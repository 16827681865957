import { CollectionDbEnum, Product } from '../types';
import {
    collection,
    db,
    getDocs,
    query,
    where,
} from './config';

export const productsModels = ()=> {

    const getProducts = async(organisationID: string)=> {
        try {
            let products:Product[] = [];
            const q = query(
                collection(db, CollectionDbEnum.Products),
                where("_organisationID", "==", organisationID),
            );

            const querySnapshot = await getDocs(q);

            for (const doc of querySnapshot.docs) {
                const product = doc.data() as Product;
                products.push(product);
            }

            return {
                code: 200,
                data: products
            };
        } catch (error) {
            return {
                code: 500
            }   
        }
    }

    return {
        getProducts,
    }

}