import React, { useCallback, useMemo } from "react";
import { SuccessProps, steps } from "./interfaces";

import styles from "./styles.module.css";
import { RestaurantOrderStatusEnum } from "../../types";

import OrderStep_0 from "../../assets/svgImages/orderStep_0.svg";
import OrderStep_1 from "../../assets/svgImages/orderStep_1.svg";
import OrderStep_2 from "../../assets/svgImages/orderStep_2.svg";
import OrderStep_3 from "../../assets/svgImages/orderStep_3.svg";
import { Button, RecapCommandOnly } from "../../components";
import { formatNumberToPrice, generateOrderNumber } from "../../functions";

export const SuccessComponent: React.FC<SuccessProps> = ({
  sale,
  logo,
  dinnerTable,
  navigateAndReset,
  onDeleteSaleAction,
}) => {
  const tableNumber = String(dinnerTable?.tableNumber).padStart(2, "0") || "00";

  const selectedOrder = steps.find(
    (el) => el.state === sale?.restaurantOrderStatus!
  );

  const isOrderStatusNew = useMemo(
    () => sale?.restaurantOrderStatus === RestaurantOrderStatusEnum.new,
    [sale]
  );

  const isOrderStatusInTable = useMemo(
    () => sale?.restaurantOrderStatus === RestaurantOrderStatusEnum.inTable,
    [sale]
  );

  const buttonTitle: string = useMemo(
    () => (isOrderStatusNew ? "Modifier ma commande" : "Valider"),
    [isOrderStatusNew]
  );
  const goToBasket = useCallback(() => {
    onDeleteSaleAction(sale?._id!);
  }, [onDeleteSaleAction, sale]);

  const renderButton = useCallback(() => {
    if (isOrderStatusNew) {
      return (
        <div className={styles.buttonContainer}>
          <Button buttonName={buttonTitle} onButtonPress={goToBasket} />
        </div>
      );
    } else {
      return (
        <div className={styles.buttonContainer}>
          <Button
            disabled={!isOrderStatusInTable}
            buttonName={buttonTitle}
            onButtonPress={navigateAndReset}
          />
        </div>
      );
    }
  }, [
    buttonTitle,
    goToBasket,
    isOrderStatusInTable,
    isOrderStatusNew,
    navigateAndReset,
  ]);

  const renderSteper = useCallback(() => {
    return (
      <div className={styles.stepsContainer}>
        {steps.map((el, i) => {
          return el.state === selectedOrder?.state ? (
            <div key={i} className={styles.progressBar}>
              <div className={styles.fill}></div>
            </div>
          ) : (
            <div
              className={
                selectedOrder?.order! < el.order
                  ? styles.disabledProgressBar
                  : styles.enabledProgressBar
              }
            />
          );
        })}
      </div>
    );
  }, [selectedOrder]);

  const renderOrderDetails = useCallback(() => {
    return (
      <div className={styles.orderDetailsContainer}>
        <div className={styles.orderDetailsLeftContainer}>
          <p className={styles.orderDetailsTxt}>Status: Non payé</p>
          <p className={styles.orderDetailsTxt}>Sur place</p>
        </div>
        <div className={styles.orderDetailsRightContainer}>
          <p className={styles.orderDetailsTxt}>N° de table: {tableNumber}</p>
          <p className={styles.orderDetailsTxt}>
            N° de commande: {generateOrderNumber(sale?.createAt!)}
          </p>
        </div>
      </div>
    );
  }, [sale]);

  const renderOrderStateImg = useCallback(() => {
    let imgsrc = "";

    switch (selectedOrder?.state) {
      case RestaurantOrderStatusEnum.new:
        imgsrc = OrderStep_0;
        break;
      case RestaurantOrderStatusEnum.inProgress:
        imgsrc = OrderStep_1;
        break;
      case RestaurantOrderStatusEnum.inTable:
        imgsrc = OrderStep_2;
        break;
      case RestaurantOrderStatusEnum.ready:
        imgsrc = OrderStep_3;
        break;
    }

    return (
      <div className={styles.imgStepContainer}>
        <img src={imgsrc} alt={selectedOrder?.title} />
      </div>
    );
  }, [selectedOrder]);

  const renderRecap = useCallback(()=> {
    if(sale === null) return;

    return (
      <RecapCommandOnly
        basket={sale.basket}
        paymentMethod={sale.paymentMethode}
        totalToPay={formatNumberToPrice(sale.totalToPay)}
        customerInfos={{
          name: sale.restauLocalOrderData?.clientInfos?.clientName!,
          phone: sale.restauLocalOrderData?.clientInfos?.clientPhone!
        }}
      />
    )
  }, [sale]);

  console.log('sale==>', sale);

  return (
    <div className={styles.container}>
      <h3 className={styles.StepTitle}>{selectedOrder?.title}</h3>
      <p className={styles.StepSubTitle}>{selectedOrder?.subTitle}</p>
      {renderSteper()}

      {renderOrderDetails()}

      <div className={styles.recapOrderContainer}>
        {renderOrderStateImg()}
        
        {renderRecap()}
      </div>
      {renderButton()}
    </div>
  );
};
