import React, { useCallback, useEffect, useState } from "react";

import { SuccessComponent } from "./Success.component";
import { useGetSalesData } from "../../hooks/useSalesData";
import {
  CLEAN_BASKET_REQUEST,
  DELETE_SALE_REQUEST,
  RESET_SALE_STATE,
} from "../../modules";
import { useDispatch, useSelector } from "react-redux";
import { DinnerTableType, LOCAL_STORAGE } from "../../types";
import { useNavigation, useSessionData } from "../../hooks";
import { enumNavigationPath } from "../../enums";
import bymeLogo from "../../assets/svgImages/bymeLogo.svg";

export const Success: React.FC = () => {
  const dispatch = useDispatch();
  const { navigateTo, goToNewTab } = useNavigation();
  const { sessionData } = useSessionData();

  const { sale, navigateAndRestSales } = useGetSalesData();

  const isDeleteSaleSuccess = useSelector(
    (state: any) => state.TransactionReducers.deleteSaleSuccessState
  );

  const isDeleteSaleError = useSelector(
    (state: any) => state.TransactionReducers.deleteSaleErrorState
  );

  const onResetSaleState = useCallback(() => {
    return dispatch({ type: RESET_SALE_STATE });
  }, [dispatch]);

  const dinnerTable: DinnerTableType | null = useSelector(
    (state: any) => state.DinnerTablesReducers.dinnerTable
  );

  useEffect(() => {
    if (isDeleteSaleSuccess) {
      navigateTo(enumNavigationPath.basket);
      onResetSaleState();
    }

    if (isDeleteSaleError) {
      alert("La suppression ne s'est pas effectuée, veuillez réessayer");
      window.localStorage.removeItem(LOCAL_STORAGE.SALE_KEY);
    }
  }, [isDeleteSaleError, isDeleteSaleSuccess, navigateTo, onResetSaleState]);

  const onDeleteSale = useCallback(
    (saleID: string) => {
      return dispatch({
        type: DELETE_SALE_REQUEST,
        payload: saleID,
      });
    },
    [dispatch]
  );
  const onCleanBasket = useCallback(() => {
    return dispatch({
      type: CLEAN_BASKET_REQUEST,
    });
  }, [dispatch]);

  const handleOpenNewTab = useCallback(() => {
    const url = enumNavigationPath.bill; // Replace this with the URL you want to open in the new tab
    goToNewTab(url);
  }, [goToNewTab]);

  const handleReset = useCallback(() => {
    handleOpenNewTab();
    setTimeout(() => {
      onCleanBasket();
      navigateAndRestSales();
    }, 1000);
  }, [navigateAndRestSales, onCleanBasket, handleOpenNewTab]);

  return (
    <SuccessComponent
      dinnerTable={dinnerTable}
      onDeleteSaleAction={onDeleteSale}
      navigateAndReset={handleReset}
      sale={sale}
      logo={sessionData?.logos ? sessionData.logos.logoMediumImage : bymeLogo}
    />
  );
};
