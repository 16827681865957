import React, { useCallback } from "react";
import {ProductComponent} from './Product.component';

import { useDispatch, useSelector } from 'react-redux';
import { 
  ADD_PRODUCT_IN_BASKET_REQUEST, 
} from "../../modules";
import { 
  basketType,
} from "../../types";
  
export const Product:React.FC = ()=> {

  const dispatch = useDispatch();
  const basket = useSelector((state: any)=> state.BasketReducers.basket);
  const selectedProduct = useSelector((state: any)=> state.ProductReducers.selectedProduct);

  const onAddProductInBasket = useCallback((basket: basketType[], newItem: basketType)=> {
    return dispatch({ type: ADD_PRODUCT_IN_BASKET_REQUEST, payload: {basket, newItem} });
  }, [dispatch]);

  return (
    <ProductComponent 
      basket={basket}
      selectedProduct={selectedProduct}
      onAddProductInBasket={onAddProductInBasket}
    />
  )
}