import { RecapProps } from "./interfaces";
import styles from "./styles.module.css";
import ChampionCup from "../../assets/svgIcons/champion-cup.svg";
import Emoji from "../../assets/svgIcons/emoji-laugh.svg";
import { AppHeader, RecapComponent } from "../../components";
import { RestaurantOrderStatusEnum, Sale } from "../../types";
import { useCallback, useMemo } from "react";
import { enumNavigationPath, enumPaymentType } from "../../enums";
import { formatNumberToPrice, generateOrderNumber } from "../../functions";
import { useNavigation } from "../../hooks";

import uuid from "react-uuid";

export const RecapComponentPage: React.FC<RecapProps> = ({
  basket,
  sessionData,
  paymentTypeSave,
  dinnerTable,
  customerInformations,
  onAddNewSale,
}) => {
  const { navigateTo, navigate } = useNavigation();

  const commandType =
    paymentTypeSave.type !== enumPaymentType.mobilMoney
      ? paymentTypeSave.type
      : `${paymentTypeSave.type}, ${paymentTypeSave.isMobilPayment}`;

  const totalBasket = useCallback(() => {
    let total = 0;

    basket.map((el) => {
      const price =
        el.productSellingPrice[el.productSellingPrice.length - 1].price;
      total += price * el.quantitySelected;
      el.extraSideDish?.map((supp) => {
        total += supp.price;
      });
    });

    return total;
  }, [basket]);

  const date = new Date();

  const sale: Sale = useMemo(() => {
    return {
      _organisationID: sessionData?._id!,
      _organisationType: sessionData?._organisationType!,
      _id: uuid(),
      basket: basket,
      totalToPay: totalBasket(),
      totalPay: 0,
      reduction: 0,
      paymentMethode: {
        methode: paymentTypeSave.type,
        mobilePayment: paymentTypeSave.isMobilPayment || null,
      },
      restauLocalOrderData: {
        table: dinnerTable ? dinnerTable : null,
        clientInfos: customerInformations ? {
          clientName: customerInformations.name,
          clientPhone: customerInformations.phone,
        } : null,
      },
      restaurantOrderStatus: RestaurantOrderStatusEnum.new,
      createAt: String(date),
    };
  }, [
    sessionData,
    basket, 
    totalBasket, 
    paymentTypeSave,
    dinnerTable, 
    customerInformations, 
    date,
  ]);

  const tableNumber = String(dinnerTable?.tableNumber).padStart(2, "0") || "00";

  const reactpData = {
    status: "Non Payé",
    tableNumber: tableNumber,
    commandType: commandType,
    commandNumber: generateOrderNumber(String(date)),
    sale: sale,
    totalToPay: formatNumberToPrice(totalBasket()),
    customerInfos: customerInformations
  };

  const onButtonPress = useCallback(() => {
    onAddNewSale(sale);
  }, [onAddNewSale, sale]);

  return (
    <div className={styles.container}>
      <AppHeader
        withBasket
        canGoback
        onBasketPress={() => {
          navigateTo(enumNavigationPath.basket);
        }}
        onGoBack={() => navigate(-1)}
      />
      <div className={styles.titleRow}>
        <img src={ChampionCup} alt="Champion cup" />

        <label className={styles.titleText}>Recapitulatif commande</label>
        <img src={Emoji} alt="Emoji laugh" />
      </div>

      <RecapComponent {...reactpData} />

      <div className={styles.buttonContainer}>
        <div onClick={onButtonPress} className={styles.validationButon}>
          <label>Valider</label>
        </div>
      </div>
    </div>
  );
};
