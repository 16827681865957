import {
    put,
    call,
    takeEvery
} from 'redux-saga/effects';

import { 
    GET_ALL_PRODUCTS_FAILURE,
    GET_ALL_PRODUCTS_REQUEST, 
    GET_ALL_PRODUCTS_SUCCESS,
    SELECTED_PRODUCT_REQUEST,
    SELECTED_PRODUCT_SUCCESS,
} from './actions';

import { productsModels } from '../../database';

function* getAllProductRequest({ payload }: any): any {
    const {getProducts} = productsModels();
    const response = yield call(getProducts, payload);

    if(response.code === 200){
        yield put({type: GET_ALL_PRODUCTS_SUCCESS, payload: response.data});
    } else {
        yield put({type: GET_ALL_PRODUCTS_FAILURE, payload: true});
    }
}

function* selectedProductRequest({ payload }: any): any {
    yield put({type: SELECTED_PRODUCT_SUCCESS, payload: payload});
}

export default function* productsSaga() {
    yield takeEvery(GET_ALL_PRODUCTS_REQUEST, getAllProductRequest);
    yield takeEvery(SELECTED_PRODUCT_REQUEST, selectedProductRequest);
}