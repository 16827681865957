import React, { useCallback, useMemo } from "react";
import { AppHeaderProps } from "./inteface";
import GoBack from "../../../assets/svgIcons/goback.svg";
import Basket from "../../../assets/svgIcons/basket.svg";
import styles from "./styles.module.css";
import BymeLogo from "../../../assets/svgImages/bymeLogo.svg";
import { useNavigation, useSessionData } from "../../../hooks";
import { useLocation } from "react-router-dom";
import { enumNavigationPath } from "../../../enums";
import { useSelector } from "react-redux";

export const AppHeader: React.FC<AppHeaderProps> = ({
  onBasketPress,
  onGoBack,
  logo,
  canGoback = false,
  withBasket = false,
}) => {
  const { navigateTo } = useNavigation();
  const { sessionData } = useSessionData();
  const organisationLogo = sessionData?.logos?.logoMediumImage;
  const basket = useSelector((state: any) => state.BasketReducers.basket);
  const numberProducts = useMemo((): number => basket.length, [basket]);
  const location = useLocation();
  const currentRouteName = location?.pathname;
  const handleGoBack = useCallback(() => {
    onGoBack?.();
  }, [onGoBack]);

  const handleGoHome = useCallback(() => {
    const homePath = `/${sessionData?.organisationNameParsed}/acceuil`;
    if (currentRouteName === homePath) return;
    navigateTo(enumNavigationPath.home);
  }, [currentRouteName, sessionData, navigateTo]);

  const handleBasketPress = useCallback(() => {
    onBasketPress?.();
  }, [onBasketPress]);

  const renderBadge = useCallback(() => {
    if (numberProducts === 0) return null;

    return (
      <div className={styles.badgeWrapper}>
        <div className={styles.badge}>
          <div
            className={styles.badgeText}
          >
            {`${numberProducts < 100 ? numberProducts : "99+"}`}
          </div>
        </div>
      </div>
    );
  }, [
    numberProducts
  ]);

  const canCenter = !canGoback && !withBasket;
  return (
    <div className={canCenter ? styles.headerRowBis : styles.headerRow}>
      {canGoback && (
        <div className={styles.iconContainer} onClick={handleGoBack}>
          <img src={GoBack} alt="Retour" />
        </div>
      )}
      <div className={styles.iconContainer} onClick={handleGoHome}>
        <img
          className={styles.iconContainer}
          src={organisationLogo ? organisationLogo : BymeLogo}
          alt="logo"
        />
      </div>
      {withBasket && (
        <div className={styles.iconContainer} onClick={handleBasketPress}>
          <div className={styles.basketWrapper}>
            <img src={Basket} alt="Panier" />
            {renderBadge()}
          </div>
        </div>
      )}
    </div>
  );
};
