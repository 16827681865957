import { combineReducers } from "redux";

// Reducers
import TransactionReducers from "./transactions/reducers";
import BasketReducers from "./basket/reducers";
import CategoriesReducers from "./categories/reducers";
import ShopSessionReducers from "./shopSession/reducers";
import ProductReducers from "./products/reducers";
import PaymentTypeReducers from "./payment/reducers";
import DinnerTablesReducers from "./dinnerTables/reducers";
import BannersReducers from "./banners/reducers";
import CustomerInfoReducers from './CustomerInformation/reducers'
export default combineReducers({
  TransactionReducers,
  BasketReducers,
  CategoriesReducers,
  ShopSessionReducers,
  ProductReducers,
  PaymentTypeReducers,
  DinnerTablesReducers,
  BannersReducers,
  CustomerInfoReducers
});
