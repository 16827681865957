import React from "react";
import { RecapCommandOnlyPropss } from "./interface";
import styles from "./styles.module.css";
import { formatNumberToPrice, limitText_15 } from "../../../functions";
import { CustomerInfoType } from "../../../types";

export const RecapCommandOnly: React.FC<RecapCommandOnlyPropss> = ({
  basket,
  paymentMethod,
  totalToPay,
  customerInfos
}) => {
  const currentcy = "fcfa";
  const { phone, name } = customerInfos as CustomerInfoType;

  return (
    <div className={styles.recapContainer}>
      <div className={styles.recapcmd}>
        <label>Récapitulatif de la commande:</label>
      </div>
      <div className={styles.pricingRow}>
        <div className={styles.titleText}>
          <label>Commandes({basket.length})</label>
        </div>
        <div className={styles.titleText}>
          <label>Prix</label>
        </div>
      </div>
      {basket.map((productBasket, i) => {
        const price =
          productBasket.productSellingPrice[
            productBasket.productSellingPrice.length - 1
          ].price;
        return (
          <div key={i} className={styles.pricingRow}>
            <div className={styles.priceItem}>
              <label className={styles.priceItem}>
                {`${limitText_15(productBasket.productName)} (${productBasket.quantitySelected
                  })`}
              </label>
            </div>

            <div className={styles.priceItem}>
              <label>
                {`${formatNumberToPrice(
                  price * productBasket.quantitySelected
                )} ${currentcy}`}
              </label>
            </div>
          </div>
        );
      })}
      <div className={styles.separator} />
      {basket.map((prodInBasket, i) => {
        if (!prodInBasket?.extraSideDish) return;

        return (
          <>
            {!!prodInBasket.sideDishSelected && (
              <div className={styles.row}>
                <label className={styles.recapcmd}>
                  Accompagnement: {limitText_15(prodInBasket.sideDishSelected)}
                </label>
              </div>
            )}
            {prodInBasket?.extraSideDish.map((extra, index) => (
              <div key={i} className={styles.pricingRow}>
                <div className={styles.priceItem}>
                  <label>{limitText_15(extra.name)}</label>
                </div>
                <div className={styles.priceItem}>
                  <label>
                    {formatNumberToPrice(extra.price)} {currentcy}
                  </label>
                </div>
              </div>
            ))}
          </>
        );
      })}
      <div className={styles.totalRow}>
        <label>
          Total à payer: {totalToPay} {currentcy}
        </label>
      </div>
      <div className={styles.paymentMode}>
        <label>Mode de paiement : {paymentMethod?.methode ?? ""}</label>
      </div>
      <div className={styles.recapcmd}>
        <label>Informations de livraison:</label>
      </div>
      <div className={styles.priceItem}>
        <label className={styles.priceItem}>
          {`${name}`}
        </label>
      </div>
      <div className={styles.priceItem}>
        <label className={styles.priceItem}>
          {`Téléphone: ${phone}`}
        </label>
      </div>
      {/* {message !== '' && (
        <div className={styles.priceItem}>
          <label className={styles.priceItem}>
            {`Commentaires: ${message}`}
          </label>
        </div>
      )} */}
    </div>
  );
};
