import React, { useCallback, useState } from "react";

import {ProductProps} from './interfaces';

import styles from './styles.module.css';
import { enumNavigationPath } from "../../enums/enumNavigationPath";
import { AppHeader, Checkbox, SelectedBox } from "../../components";
import { useNavigation } from "../../hooks";
import { formatNumberToPrice } from "../../functions";
import { sideDishType } from "../../types";

export const ProductComponent:React.FC<ProductProps> = ({
    selectedProduct,
    basket,
    onAddProductInBasket,
})=> {

    const {navigateTo, navigate} = useNavigation();
    const [supplementsList, setSupplementsList] = useState<any[]>([]);
    const [selectedAcc, setSelectedAcc] = useState<string>('');

    const isExist = basket.find(el=> el._id === selectedProduct._id) ? true : false;
    const productPrice = formatNumberToPrice(selectedProduct.productSellingPrice[selectedProduct.productSellingPrice.length - 1].price);

    const handleGoTobasket = useCallback(()=> {
        navigateTo(enumNavigationPath.basket);
    }, [navigateTo]);

    const handleAddTobasket = useCallback(()=> {
        const sideDishSelected = selectedAcc || null;
        const extraSideDish = supplementsList.length > 0 ? supplementsList : null;
        onAddProductInBasket(basket, {
            ...selectedProduct,
            quantitySelected: 0,
            sideDishSelected: sideDishSelected,
            extraSideDish: extraSideDish,
        });
        setSelectedAcc('');
        setSupplementsList([]);
    }, [
        basket, 
        onAddProductInBasket, 
        selectedAcc, 
        selectedProduct, 
        supplementsList
    ]);

    const onSetSupplementList = useCallback((sideDish: sideDishType)=> {
        let tmpSideDishList = [...supplementsList];
        const sideDishExistInList = supplementsList.find(el=> el.name === sideDish.name);
        if(sideDishExistInList){
            let result = tmpSideDishList.filter(el=> el.name !== sideDishExistInList.name);
            setSupplementsList(result);
        } else {
            setSupplementsList([...supplementsList, sideDish]);
        }
    }, [supplementsList]);

    const renderProductHead = useCallback(()=> {
        return (
            <div
                style={{
                    backgroundImage: `url(${selectedProduct.productImage})`
                }}
                className={styles.productHeadContainer}
            >
                <div className={styles.darkScreen}>
                    <h3 className={styles.productName}>
                        {selectedProduct.productName}
                    </h3>

                    <div
                        className={styles.priceContainer}
                    >
                        <label>
                            {productPrice} fcfa
                        </label>
                    </div>
                </div>
            </div>
        )
    }, [productPrice, selectedProduct]);

    const renderSideDish = useCallback(()=> {
        if(selectedProduct.sideDish?.length === 0) return;
        return (
            <div className={styles.sideDishContainer}>
                <div className={styles.sideDishSubContainer}>
                    <div className={styles.sideDishTxtContainer}>
                        <label>
                            Accompagnements
                        </label>
                    </div>
                    <div className={styles.line} />
                </div>
                <div className={styles.sideDishItemList}>
                    {
                        selectedProduct.sideDish?.map((el, i)=> {
                            return (
                                <div
                                    key={i}
                                    className={styles.sideDishItem}
                                >
                                    <Checkbox 
                                        placeholder={el.name}
                                        isSelected={el.name === selectedAcc}
                                        onCheckboxPress={()=> {
                                            setSelectedAcc(el.name);
                                        }}
                                    />
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )
    }, [
        selectedProduct, 
        selectedAcc
    ]);

    const renderExtraSideDish = useCallback(()=> {
        if(selectedProduct.sideDish?.length === 0 || !selectedAcc) return;
        return (
            <div className={styles.sideDishContainer}>
                <div className={styles.sideDishSubContainer}>
                    <div className={styles.sideDishTxtContainer}>
                        <label>
                            Supplements
                        </label>
                    </div>
                    <div className={styles.line} />
                </div>
                <div className={styles.sideDishItemList}>
                    {
                        selectedProduct.sideDish?.map((el, i)=> {
                            const isSelected = supplementsList.find(supp=> supp.name === el.name);
                            return (
                                <div
                                    key={i}
                                    className={styles.extraSideDishItem}
                                >
                                    <SelectedBox 
                                        placeholder={el.name}
                                        isSelected={isSelected}
                                        onCheckboxPress={()=> onSetSupplementList(el)}
                                    />
                                    <label className={styles.extraSideDishItemPrice}>
                                        +{el.price} Fcfa
                                    </label>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )
    }, [
        onSetSupplementList, 
        selectedProduct, 
        supplementsList,
        selectedAcc
    ]);

    return (
        <div className={styles.container}>
            <div className={styles.AppHeaderContainer}>
                <AppHeader
                    withBasket
                    canGoback
                    onBasketPress={()=> {
                    navigateTo(enumNavigationPath.basket);
                    }}
                    onGoBack={()=> navigate(-1)}
                />
            </div>
            {renderProductHead()}
            
            <div className={styles.descriptionContainer}>
                <p className={styles.descriptionTxt}>
                    {selectedProduct.productDescription}
                </p>
            </div>

            {renderSideDish()}

            {renderExtraSideDish()}

            <div 
                className={styles.addInBasketBtn}
                onClick={isExist ? handleGoTobasket : handleAddTobasket}
                style={{
                    backgroundColor: isExist ? '#fff' : '#FF885A',
                    color: isExist ? '#FF885A' : '#fff',
                }}
            >
                <label>
                    {
                        isExist ? 'Voir mon panier'
                        : 'Ajouter au panier'
                    }
                </label>
            </div>
        </div>
    )
}