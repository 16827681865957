import { OrganisationTypeEnum } from "./organisation.types"

export interface Stock {
    _organisationID: string
    _organisationType: OrganisationTypeEnum | string
    _id: string
    stockName: string
    stockQuantity: number
    stockUnit: StockUnitEnum | string
    stockSupply: StockSupplyType[]
    stockUsing: StockUsingType[]
}

export interface StockUsingType {
    quantity: number
    date: string
}

export interface StockSupplyType {
    _supplierID?: string
    quantity: number
    productPurchasePrice: number
    date: string
}

export interface stockInitialState {
    allStocks: Stock[]
    getAllStockFailure: boolean
    addStockSuccess: boolean
    addStockFailure: boolean
    updatedStockSuccess: boolean
    updatedStockFailure: boolean
    deleteStockSuccess: boolean
    deleteStockFailure: boolean
}

export interface stocksActionType {
    type: string
    payload: any
}

export enum StockUnitEnum {
    unit = 'unité',
    mass = 'kg',
    capacity = 'l',
}