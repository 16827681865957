import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { enumNavigationPath } from "../enums/enumNavigationPath";

import {
  NotFound,
  Home,
  Onboarding,
  Carte,
  Product,
  Basket,
  Success,
  Payment,
  Recap,
  BillScreeen,
  CustomerInformation,
} from "../screens";

export const Navigations: React.FC<any> = () => {
  const router = createBrowserRouter([
    {
      path: "*",
      element: <NotFound />,
    },
    {
      path: enumNavigationPath.onboarding,
      element: <Onboarding />,
    },
    {
      path: enumNavigationPath.onboarding + enumNavigationPath.home,
      element: <Home />,
    },
    {
      path: enumNavigationPath.onboarding + enumNavigationPath.carte,
      element: <Carte />,
    },
    {
      path: enumNavigationPath.onboarding + enumNavigationPath.product,
      element: <Product />,
    },
    {
      path: enumNavigationPath.onboarding + enumNavigationPath.basket,
      element: <Basket />,
    },
    {
      path: enumNavigationPath.onboarding + enumNavigationPath.payment,
      element: <Payment />,
    },
    {
      path: enumNavigationPath.onboarding + enumNavigationPath.recap,
      element: <Recap />,
    },
    {
      path: enumNavigationPath.onboarding + enumNavigationPath.success,
      element: <Success />,
    },
    {
      path: enumNavigationPath.onboarding + enumNavigationPath.bill,
      element: <BillScreeen />,
    },
    {
      path: enumNavigationPath.onboarding + enumNavigationPath.customerInformation,
      element: <CustomerInformation />,
    },
  ]);

  return <RouterProvider router={router} />;
};
