import { useCallback, useEffect, useState } from "react";
import { collection, db, onSnapshot, query, where } from "../database/config";
import { CollectionDbEnum, LOCAL_STORAGE, Sale } from "../types";
import { useNavigation } from "./useNavigation";
import { enumNavigationPath } from "../enums";
import { useSessionData } from "./useSessionData";

export const useGetSalesData = () => {
  const [sale, setSale] = useState<Sale | null>(null);
  const { sessionData } = useSessionData();

  const { navigateTo } = useNavigation();

  useEffect(() => {
    const result = window.localStorage.getItem(LOCAL_STORAGE.SALE_KEY);
    if (result) {
      const resultParsed: Sale = JSON.parse(result!);

      const q = query(
        collection(db, CollectionDbEnum.Sales),
        where("_organisationID", "==", sessionData?._id),
        where("_id", "==", resultParsed._id)
      );
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        let transactions: Sale[] = [];
        querySnapshot.forEach((doc) => {
          transactions.push(doc.data() as Sale);
        });
        updateSaleInLocalStorage(resultParsed, transactions[0]);
        setSale(transactions[0]);
      });
    }
  }, [sessionData]);

  const updateSaleInLocalStorage = useCallback(
    (saleInLocalStorage: Sale, databaseSale: Sale) => {
      if (
        saleInLocalStorage.restaurantOrderStatus !==
        databaseSale.restaurantOrderStatus
      ) {
        window.localStorage.setItem(
          LOCAL_STORAGE.SALE_KEY,
          JSON.stringify(databaseSale)
        );
      }
    },
    []
  );

  const navigateAndRestSales = useCallback(() => {
    window.localStorage.removeItem(LOCAL_STORAGE.SALE_KEY);
    navigateTo(enumNavigationPath.home);
  }, [navigateTo]);

  return { navigateAndRestSales, sale };
};
