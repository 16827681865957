import React, { useCallback } from "react";
import {PaymentComponent} from './Payment.component';
import { useDispatch, useSelector } from 'react-redux';
import { paymetTypeState, SET_PAYMENT_TYPE_REQUEST } from "../../modules";
  
export const Payment:React.FC = ({})=> {

  const dispatch = useDispatch();

  const paymentTypeSave = useSelector((state: any)=> state.PaymentTypeReducers.payment);

  const onSelectedPayment = useCallback((payment: paymetTypeState)=> {
    return dispatch({ type: SET_PAYMENT_TYPE_REQUEST, payload: payment });
  }, [dispatch]);

  return (
    <PaymentComponent 
      paymentTypeSave={paymentTypeSave}
      onSelectedPayment={onSelectedPayment}
    />
  )
}