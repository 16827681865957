import React from "react";

import {NotFoundProps} from './interfaces';

import styles from './styles.module.css';

export const NotFoundComponent:React.FC<NotFoundProps> = ({

})=> {

    return (
        <div className={styles.container}>
            Hello NotFound
        </div>
    )
}