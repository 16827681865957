import { put, call, takeEvery } from "redux-saga/effects";

import {
  DELETE_SALE_FAILURE,
  DELETE_SALE_REQUEST,
  DELETE_SALE_SUCCESS,
  SET_NEW_TRANSACTION_FAILURE,
  SET_NEW_TRANSACTION_REQUEST,
  SET_NEW_TRANSACTION_SUCCESS,
} from "./actions";
import { dinnerTablesModels, transactionsModels } from "../../database";
import { DinnerTableType } from "../../types";

function* setNewTransactionRequest({ payload }: any): any {
  const { newTransaction } = transactionsModels();
  const { updatedDinnerTableData } = dinnerTablesModels();
  console.log('===payload==>', payload);
  const response = yield call(newTransaction, payload);
  if (response.code === 200) {
    const newDinnerTable: DinnerTableType = {
      ...payload.dinnTable,
      isUsed: true,
    };
    yield call(updatedDinnerTableData, newDinnerTable);
    yield put({ type: SET_NEW_TRANSACTION_SUCCESS, payload: true });
  } else {
    yield put({ type: SET_NEW_TRANSACTION_FAILURE, payload: true });
  }
}

function* deleteSaleRequest({ payload }: any): any {
  const { deleteSale } = transactionsModels();
  const response = yield call(deleteSale, payload);
  if (response.code === 200) {
    yield put({ type: DELETE_SALE_SUCCESS, payload: true });
  } else {
    yield put({ type: DELETE_SALE_FAILURE, payload: true });
  }
}

export default function* transactionSaga() {
  yield takeEvery(SET_NEW_TRANSACTION_REQUEST, setNewTransactionRequest);
  yield takeEvery(DELETE_SALE_REQUEST, deleteSaleRequest);
}
