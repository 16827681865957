import React from "react";

import {ProductBasketCardProps} from './interfaces';

import styles from './styles.module.css';
import { formatNumberToPrice, limitText_15 } from "../../../../functions";

export const ProductBasketCard:React.FC<ProductBasketCardProps> = ({
    productInBasket,
    onDeleteProductInBasket,
    onAddProductInBasket,
})=> {

    const price = formatNumberToPrice(
        productInBasket.productSellingPrice[
            productInBasket.productSellingPrice.length - 1
        ].price * productInBasket.quantitySelected
    );

    return (
        <div className={styles.container}>
            <div className={styles.leftContentContainer}>
                <img 
                    src={productInBasket.productImage}
                    alt={productInBasket.productName}
                    className={styles.productImg}
                />
                <div className={styles.productDetailsContainer}>
                    <label className={styles.productName}>
                        {limitText_15(productInBasket.productName)}
                    </label>
                    <label className={styles.productPrice}>
                        {price} fcfa
                    </label>
                </div>
            </div>

            <div className={styles.rightContentContainer}>
                <div 
                    className={styles.down}
                    onClick={()=> onDeleteProductInBasket(productInBasket)}
                >
                    <label>-</label>
                </div>

                <label className={styles.quantity}>
                    {productInBasket.quantitySelected}
                </label>

                <div 
                    className={styles.up}
                    onClick={()=> onAddProductInBasket(productInBasket)}
                >
                    <label>+</label>
                </div>
            </div>
        </div>
    )
}