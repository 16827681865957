import {
    put,
    call,
    takeEvery
} from 'redux-saga/effects';

import * as actions from './actions';
import { categoriesModel } from '../../database';

function* getAllCategoriesRequest({ payload }: any): any {
    const {getCategories} = categoriesModel();

    const response = yield call(getCategories, payload);
    if(response.code === 200){
        yield put({type: actions.GET_ALL_CATEGORIES_SUCCESS, payload: response.data});
    } else {
        yield put({type: actions.GET_ALL_CATEGORIES_FAILURE, payload: true});
    }
}

function* selectedCategory({ payload }: any): any {
    yield put({type: actions.SELECTED_CATEGORY_SUCCESS, payload: payload});
}

export default function* categoriesSaga() {
    yield takeEvery(actions.GET_ALL_CATEGORIES_REQUEST, getAllCategoriesRequest);
    yield takeEvery(actions.SELECTED_CATEGORY_REQUEST, selectedCategory);
}