import { spawn } from "redux-saga/effects";

// Sagas
import transactionSaga from "./transactions/saga";
import basketSaga from "./basket/saga";
import categoriesSaga from "./categories/saga";
import shopSessionReducersSaga from "./shopSession/saga";
import productsSaga from "./products/saga";
import paymentSaga from "./payment/saga";
import dinnerTablesSaga from "./dinnerTables/saga";
import bannersSaga from "./banners/saga";

// Export the root saga
export default function* rootSaga() {
  yield spawn(transactionSaga);
  yield spawn(basketSaga);
  yield spawn(categoriesSaga);
  yield spawn(shopSessionReducersSaga);
  yield spawn(productsSaga);
  yield spawn(paymentSaga);
  yield spawn(dinnerTablesSaga);
  yield spawn(bannersSaga);
}
