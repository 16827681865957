import React, { useCallback, useEffect, useState } from "react";

import {CarteComponent} from './Carte.component';

import { useDispatch, useSelector } from 'react-redux';
import { 
  GET_ALL_PRODUCTS_REQUEST, 
  SELECTED_CATEGORY_REQUEST, 
  SELECTED_PRODUCT_REQUEST
} from "../../modules";
import { Product, productsByCategories } from "../../types";
import { useSessionData } from "../../hooks";
import { groupProductsByCategories } from "../../functions";
  
export const Carte:React.FC = ()=> {

  const dispatch = useDispatch();
  const { sessionData } = useSessionData();
  const [productsByCategories, setProductsByCategories] = useState<productsByCategories[]>([]);

  const categories = useSelector((state: any)=> state.CategoriesReducers.allCategories);
  const selectedCategory = useSelector((state: any)=> state.CategoriesReducers.selectedCategory);
  const products = useSelector((state: any)=> state.ProductReducers.allProducts);
  const basket = useSelector((state: any)=> state.BasketReducers.basket);

  useEffect(()=> {
    if(categories.length > 0 && products.length > 0){
      const productsByCategoriesTmp = groupProductsByCategories(categories, products);
      setProductsByCategories(productsByCategoriesTmp);
    }
  }, [products, categories]);

  const onGetProductByCategoryId = useCallback((categoryId: string)=> {
    return dispatch({ 
      type: GET_ALL_PRODUCTS_REQUEST, 
      payload: {
        categoryId, 
        organisationID: sessionData?._id
      } 
    });
  }, [dispatch, sessionData]);

  const onSelectedCategory = useCallback((category: productsByCategories)=> {
    return dispatch({ type: SELECTED_CATEGORY_REQUEST, payload: category });
  }, [dispatch]);

  const onSelectedProduct = useCallback((product: Product)=> {
    return dispatch({ type: SELECTED_PRODUCT_REQUEST, payload: product });
  }, [dispatch]);

  return (
    <CarteComponent 
      productsByCategories={productsByCategories}
      selectedCategory={selectedCategory}
      basket={basket}
      onGetProductByCategoryId={onGetProductByCategoryId}
      onSelectedCategory={onSelectedCategory}
      onSelectedProduct={onSelectedProduct}
    />
  )
}