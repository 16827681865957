// encryptionTransform.ts
import { AES, enc } from 'crypto-js';
import { LOCAL_STORAGE } from '../types';

const SECRET_KEY = LOCAL_STORAGE.SECRET_KEY;

interface EncryptionTransform {
  in: (state: any) => string;
  out: (encryptedState: string) => any;
}

// Fonction de chiffrement
const encryptData = (data: any): string => {
  const encryptedData = AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();
  return encryptedData;
};

// Fonction de déchiffrement
const decryptData = (encryptedData: string): any => {
  const decryptedData = AES.decrypt(encryptedData, SECRET_KEY).toString(enc.Utf8);
  return JSON.parse(decryptedData);
};

// Transformateur personnalisé
const encryptionTransform: EncryptionTransform = {
  in: (state: any): string => {
    return encryptData(state);
  },
  out: (encryptedState: string): any => {
    return decryptData(encryptedState);
  },
};

export {
    encryptionTransform
};
