export const limitText_15 = (text: string)=> {
    if (text.length > 15) {
      return text.slice(0, 15) + "...";
    }
    return text;
}
export const limitText_10 = (text: string)=> {
  if (text.length > 10) {
    return text.slice(0, 10) + "...";
  }
  return text;
}