import { useDispatch } from "react-redux";
import { CustomerInformationPage } from "./CustomerInformation.component";
import { useCallback } from "react";
import { SAVE_CUSTOMER_INFO_REQUEST } from "../../modules/CustomerInformation";
import { CustomerInfoType } from "../../types";

export const CustomerInformation: React.FC = () => {
  const dispatch = useDispatch();

  const onSaveCustomerInfos = useCallback(
    (data: CustomerInfoType
    ) => {
      return dispatch({ type: SAVE_CUSTOMER_INFO_REQUEST, payload: data })
    }, [dispatch]);

  return (
    <CustomerInformationPage
      onSaveInformations={onSaveCustomerInfos}
    />
  );
};
