import {
  DELETE_SALE_FAILURE,
  DELETE_SALE_REQUEST,
  DELETE_SALE_SUCCESS,
  RESET_SALE_STATE,
  SET_NEW_TRANSACTION_FAILURE,
  SET_NEW_TRANSACTION_REQUEST,
  SET_NEW_TRANSACTION_SUCCESS,
} from "./actions";
import { transactionInitialState, actionsTransaction } from "./types";

const initialState: transactionInitialState = {
  addNewTransactionErrorState: false,
  addNewTransactionSuccess: false,
  deleteSaleSuccessState: false,
  deleteSaleErrorState: false,
};

export default function TransactionReducers(
  state: transactionInitialState = initialState,
  { type, payload }: actionsTransaction
) {
  switch (type) {
    case RESET_SALE_STATE:
      return {
        ...initialState,
      };
    case SET_NEW_TRANSACTION_REQUEST:
      return {
        ...state,
      };
    case SET_NEW_TRANSACTION_SUCCESS:
      return {
        ...state,
        addNewTransactionSuccess: payload,
        addNewTransactionErrorState: false,
      };
    case SET_NEW_TRANSACTION_FAILURE:
      return {
        ...state,
        addNewTransactionErrorState: payload,
        addNewTransactionSuccess: false,
      };
    case DELETE_SALE_REQUEST:
      return {
        ...state,
      };
    case DELETE_SALE_SUCCESS:
      return {
        ...state,
        deleteSaleSuccessState: payload,
        deleteSaleErrorState: false,
      };
    case DELETE_SALE_FAILURE:
      return {
        ...state,
        deleteSaleSuccessState: false,
        deleteSaleErrorState: payload,
      };
    default:
      return state;
  }
}
