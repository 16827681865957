import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import rootSaga from './sagas';
import rootReducer from './reducers';
import { LOCAL_STORAGE } from '../types';
import { encryptionTransform } from '../functions';

const sagaMiddleware = createSagaMiddleware()
const persistConfig = {
  key: LOCAL_STORAGE.ROOT_KEY,
  storage,
  transforms: [encryptionTransform],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
  persistedReducer,
  {},
  composeWithDevTools(applyMiddleware(sagaMiddleware))
);

const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export {
  store,
  persistor
};