import { CustomerInfoState, actionsCustomerInfos } from "./types";
import * as actions from './actions'
import { CustomerInfoType } from "../../types";
const initialState: CustomerInfoState = {
    customerData: null
}

export default function CustomerInfoReducers(
    state: CustomerInfoState = initialState,
    { type, payload }: actionsCustomerInfos
) {
    switch (type) {
        case actions.SAVE_CUSTOMER_INFO_REQUEST:

            return {
                ...state,
                customerData: payload
            }

        case actions.CLEAN_CUSTOMER_INFO_REQUEST:
            return {
                ...initialState
            }

        default:
            return state;
    }
}