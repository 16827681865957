import * as actions from "./actions";
import { 
    actionsCategories, 
    categoriesInitialState 
} from "./types";

const initialState:categoriesInitialState = {
    allCategories: [],
    getAllCategoryErrorState: false,
    selectedCategory: null
}

export default function CategoriesReducers(
    state:categoriesInitialState = initialState, 
    {type, payload}: actionsCategories
){
    switch(type) {
        case actions.GET_ALL_CATEGORIES_REQUEST: 
            return {
                ...state,
            }
        case actions.GET_ALL_CATEGORIES_SUCCESS:
            return {
                ...state,
                allCategories: payload,
            }
        case actions.GET_ALL_CATEGORIES_FAILURE:
            return {
                ...state,
                getAllCategoryErrorState: payload
            }
        case actions.SELECTED_CATEGORY_REQUEST: 
            return {
                ...state,
            }
        case actions.SELECTED_CATEGORY_SUCCESS:
            return {
                ...state,
                selectedCategory: payload,
            }
        case actions.SELECTED_CATEGORY_FAILURE:
            return {
                ...state,
                // getAllCategoryErrorState: payload
            }
        default:
            return state
    }
}