import { mockBanners } from "../__mocks__";
import {
  BannerType,
  CollectionDbEnum,
  OrganisationTypeEnum,
  bannersType,
} from "../types";
import { collection, db, getDocs, query, where } from "./config";

export const bannersModel = () => {
  const getBanners = async (organisationID: string) => {
    try {
      let banners: bannersType = {} as bannersType;
      const q = query(
        collection(db, CollectionDbEnum.Banners),
        where("_organisationID", "==", organisationID),
        where("_organisationType", "==", OrganisationTypeEnum.Restaurant)
      );

      const querySnapshot = await getDocs(q);

      for (const doc of querySnapshot.docs) {
        const banner = doc.data() as bannersType;
        banners = banner;
      }

      return {
        code: 200,
        data: banners,
      };
    } catch (error) {
      return {
        code: 500,
      };
    }
  };

  return {
    getBanners,
  };
};
