import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import {BasketComponent} from './Basket.component';

import { 
  ADD_PRODUCT_IN_BASKET_REQUEST, 
  DELETE_PRODUCT_IN_BASKET_REQUEST,
  // UPDATED_PRODUCT_IN_BASKET_REQUEST
} from "../../modules";
import { Product, basketType } from "../../types";
  
export const Basket:React.FC = ({})=> {

  const dispatch = useDispatch();
  const [productsWithoutSideDish, setProductsWithoutSideDish] = useState<Product[]>([]);

  const basket:basketType[] = useSelector((state: any)=> state.BasketReducers.basket);
  const products:Product[] = useSelector((state: any)=> state.ProductReducers.allProducts);

  useEffect(()=> {
    let result = products.filter(product=> 
      (product.sideDish?.length === 0 || 
      product.sideDish === null) &&
      !basket.find(el=> el._id === product._id)
    );
    setProductsWithoutSideDish(result);
  }, [basket, products]);

  // const onUpdatedProductInBasket = useCallback((
  //   quantity: number, 
  //   basket: basketType[], 
  //   index: number
  // )=> {
  //   return dispatch({ 
  //     type: UPDATED_PRODUCT_IN_BASKET_REQUEST, 
  //     payload: {
  //       quantity,
  //       basket,
  //       index,
  //     } 
  // })
  // }, [dispatch]);

  const onAddProductInBasket = useCallback((newItem: basketType)=> {
    return dispatch({ type: ADD_PRODUCT_IN_BASKET_REQUEST, payload: {basket, newItem} });
  }, [dispatch, basket]);

  const onDeleteProductInBasket = useCallback((itemData: basketType)=> {
    return dispatch({ type: DELETE_PRODUCT_IN_BASKET_REQUEST, payload: {basket, itemData} });
  }, [dispatch, basket]);

  return (
    <BasketComponent 
      basket={basket}
      products={productsWithoutSideDish}
      onAddProductInBasket={onAddProductInBasket}
      onDeleteProductInBasket={onDeleteProductInBasket}
    />
  )
}
