import {
    put,
    call,
    takeEvery,
} from 'redux-saga/effects';

import * as actions from './actions';
import { dinnerTablesModels } from '../../database';

function* getDinnerTableRequest({ payload }: any): any {
    const {getDinnerTable} = dinnerTablesModels();
    const {organisationID, tableNumber} = payload;

    const response = yield call(getDinnerTable, organisationID, tableNumber);
    if(response.code === 200){
        yield put({type: actions.GET_DINNER_TABLE_SUCCESS, payload: response.data});
    } else {
        yield put({type: actions.GET_DINNER_TABLE_FAILURE, payload: true});
    }
}

function* updatedDinnerTableResquest({ payload }: any): any {
    const {updatedDinnerTableData} = dinnerTablesModels();

    const response = yield call(updatedDinnerTableData, payload);
    if(response.code === 200){
        yield put({type: actions.UPDATED_DINNER_TABLE_SUCCESS, payload: true});
    } else {
        yield put({type: actions.UPDATED_DINNER_TABLE_FAILURE, payload: true});
    }
}

export default function* dinnerTablesSaga() {
    yield takeEvery(actions.GET_DINNER_TABLE_REQUEST, getDinnerTableRequest);
    yield takeEvery(actions.UPDATED_DINNER_TABLE_REQUEST, updatedDinnerTableResquest);
}