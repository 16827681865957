import React from "react";
import styles from "./styles.module.css";
import { ItemSeparatorProps } from "./interface";
export const ItemSeparatorWrapper: React.FC<ItemSeparatorProps> = ({
  label,
  children,
}) => {
  return (
    <div className={styles.sideDishContainer}>
      <div className={styles.sideDishSubContainer}>
        <div className={styles.sideDishTxtContainer}>
          <label>{label}</label>
        </div>
        <div className={styles.line} />
      </div>
      <div className={styles.sideDishItemList}>{children}</div>
    </div>
  );
};
