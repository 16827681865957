import { db, doc, setDoc } from "./config";
import { CollectionDbEnum, Sale } from "../types";
import { deleteDoc } from "firebase/firestore";

export const transactionsModels = () => {
  const newTransaction = async (sale: Sale) => {
    try {
      const docRef = doc(db, CollectionDbEnum.Sales, sale._id);
      await setDoc(docRef, sale);

      return {
        code: 200,
      };
    } catch (error) {
      return {
        code: 500,
      };
    }
  };

  const deleteSale = async (saleID: string) => {
    try {
      const docRef = doc(db, CollectionDbEnum.Sales, saleID);
      await deleteDoc(docRef);

      return {
        code: 200,
      };
    } catch (error) {
      return {
        code: 500,
      };
    }
  };

  return {
    newTransaction,
    deleteSale,
  };
};
