import { call, put, takeEvery } from "redux-saga/effects";
import { bannersModel } from "../../database/banners";
import { GET_BANNERS_REQUEST, GET_BANNERS_SUCCESS } from "./actions";

function* getAllBanners({ payload }: any): any {
  const { getBanners } = bannersModel();

  const response = yield call(getBanners, payload);

  if (response.code === 200) {
    yield put({ type: GET_BANNERS_SUCCESS, payload: response.data });
  } else {
    yield put({ type: GET_BANNERS_SUCCESS, payload: true });
  }
}

export default function* bannersSaga() {
  yield takeEvery(GET_BANNERS_REQUEST, getAllBanners);
}
