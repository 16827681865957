import { DinnerTableActionType, DinnerTableInitialState } from "../../types";
import * as actions from "./actions";

const initialState: DinnerTableInitialState = {
    dinnerTable: null,
    getDinnerTableFailure: false,
    updatedDinnerTableSuccess: false,
    updatedDinnerTableFailure: false,
}

export default function DinnerTablesReducers(
    state: DinnerTableInitialState = initialState, 
    {type, payload}: DinnerTableActionType
){
    switch(type) {
        case actions.RESET_DINNER_TABLE_STATE:
            return {
                ...initialState,
            }
        case actions.GET_DINNER_TABLE_REQUEST:
            return {
                ...state,
            }
        case actions.GET_DINNER_TABLE_SUCCESS:
            return {
                ...state,
                dinnerTable: payload,
            }
        case actions.GET_DINNER_TABLE_FAILURE:
            return {
                ...state,
                getDinnerTableFailure: payload
            }
        case actions.UPDATED_DINNER_TABLE_REQUEST:
            return {
                ...state,
            }
        case actions.UPDATED_DINNER_TABLE_SUCCESS:
            return {
                ...state,
                updatedDinnerTableSuccess: payload,
            }
        case actions.UPDATED_DINNER_TABLE_FAILURE:
            return {
                ...state,
                updatedDinnerTableFailure: payload
            }
        default:
            return state
    }
}