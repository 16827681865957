import { Organisation, OrganisationTypeEnum } from "./organisation.types"

export interface User {
    _organisationID: string
    _organisationType: OrganisationTypeEnum | string
    _id: string
    role: UserRoleEnum | string
    firstname: string
    lastname: string
    phone: string
    mail: string
    password: string
    createAt: string
}

export interface userActionType {
    type: string
    payload: any
}

export type sessionDataType = User & Organisation;
export interface UserInitialState {
    sessionData: sessionDataType | null
    allUsers: User[]
    signupStatus: boolean
    signinSuccessStatus: boolean
    signinError: boolean
    signupError: boolean
    allUserErrorState: boolean
    activationKeyState: boolean
    activationKeyError: boolean
    updatedUserSuccess: boolean
    updatedUserFailure: boolean
    deleteUserSuccess: boolean
    deleteUserFailure: boolean
}

export enum UserRoleEnum {
    admin = 'admin',
    seller = 'server(veuse)',
    caissier = 'caissier',
    cuisine = 'commi de cuisine',
    chef = 'chef cuisinier',
}
