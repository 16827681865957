import React, { useCallback } from "react";

import { CarteProps } from "./interfaces";

import styles from "./styles.module.css";

import { useNavigation } from "../../hooks";
import { AppHeader, ProductCard } from "../../components";
import { enumNavigationPath } from "../../enums";
import { formatNumberToPrice } from "../../functions";

export const CarteComponent: React.FC<CarteProps> = ({
  selectedCategory,
  productsByCategories,
  basket,
  onGetProductByCategoryId,
  onSelectedCategory,
  onSelectedProduct,
}) => {
  const { navigateTo, navigate } = useNavigation();

  const renderCategoriesContainer = useCallback(() => {
    return (
      <div className={styles.categoriesContainer}>
        {productsByCategories.map((el, i) => {
          return (
            <div
              key={i}
              onClick={()=> onSelectedCategory(el)}
              className={
                el._id === selectedCategory._id
                  ? styles.categoryItemActive
                  : styles.categoryItemInActive
              }
            >
              <label>{el.categoryName}</label>
            </div>
          );
        })}
      </div>
    );
  }, [
    onSelectedCategory, 
    productsByCategories, 
    selectedCategory
  ]);

  const renderImageBanner = useCallback(() => {
    return (
      <div className={styles.banner}>
        <img
          src={selectedCategory.categoryImage}
          alt={selectedCategory.categoryName}
          className={styles.banner}
        />
        <div className={styles.category}>
          <label>{selectedCategory.categoryName}</label>
        </div>
      </div>
    );
  }, [selectedCategory]);

  // const renderHorizontalCards = useCallback(() => {
  //   return Array.from({ length: 3 }, (_, idx) => (
  //     <div className={styles.cardWrapper}>
  //       <HorizontalCard
  //         price="25000"
  //         productName="Coca cola"
  //         productImgUri="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRMvmnCW4SuTe5uDEFpJltj0bUZsbXd0ws-Ig&usqp=CAU"
  //         withAccompaniement={false}
  //         withDesert={false}
  //         withDrink={false}
  //         withStarter={false}
  //         onPress={() => {}}
  //       />
  //     </div>
  //   ));
  // }, []);

  const renderProductCards = useCallback(() => {
    return (
      <div className={styles.productContainerRow}>
        {
          selectedCategory.produits?.map((product, i)=> {
            const price = product.productSellingPrice[product.productSellingPrice.length - 1].price;
            return (
              <div 
                key={i}
                className={styles.productCardWrapper}
              >
                <ProductCard
                  price={formatNumberToPrice(price)}
                  productName={product.productName}
                  productImgUri={product.productImage}
                  onPress={()=> {
                    onSelectedProduct(product);
                    navigateTo(enumNavigationPath.product);
                  }}
                />
              </div>
            )
          })
        }
      </div>
    );
  }, [
    navigateTo, 
    onSelectedProduct, 
    selectedCategory
  ]);

  return (
    <div className={styles.container}>
      <AppHeader
        withBasket
        canGoback
        onBasketPress={()=> {
          navigateTo(enumNavigationPath.basket);
        }}
        onGoBack={()=> navigate(-1)}
      />
      {renderCategoriesContainer()}
      {renderImageBanner()}
      {renderProductCards()}
    </div>
  );
};
