import React from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export const pdfGenerator = (contentID: string) => {
  // Get the element to capture
  const element = document.getElementById(contentID);

  // Use html2canvas to capture the element as an image
  html2canvas(element!)
    .then((canvas) => {
      // Create a new jsPDF instance
      const pdf = new jsPDF();

      // Calculate the image's aspect ratio to fit in the PDF page
      const imgData = canvas.toDataURL("image/jpeg", 1.0);
      const imgWidth = 210; // A4 size width (mm)
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      // Add the image to the PDF
      pdf.addImage(imgData, "JPEG", 0, 0, imgWidth, imgHeight);

      // Save the PDF with a name
      pdf.save("Facture.pdf");
    })
    .finally(() => {
     setTimeout(() => {
      window.close();
     }, 1000);
    });
};
