export const GET_BASKET_REQUEST = 'GET_BASKET_REQUEST';
export const GET_BASKET_SUCCESS = 'GET_BASKET_SUCCESS';
export const GET_BASKET_FAILURE = 'GET_BASKET_FAILURE';

export const ADD_PRODUCT_IN_BASKET_REQUEST = 'ADD_PRODUCT_IN_BASKET_REQUEST';
export const ADD_PRODUCT_IN_BASKET_SUCCESS = 'ADD_PRODUCT_IN_BASKET_SUCCESS';
export const ADD_PRODUCT_IN_BASKET_FAILURE = 'ADD_PRODUCT_IN_BASKET_FAILURE';

export const DELETE_PRODUCT_IN_BASKET_REQUEST = 'DELETE_PRODUCT_IN_BASKET_REQUEST';
export const DELETE_PRODUCT_IN_BASKET_SUCCESS = 'DELETE_PRODUCT_IN_BASKET_SUCCESS';
export const DELETE_PRODUCT_IN_BASKET_FAILURE = 'DELETE_PRODUCT_IN_BASKET_FAILURE';

export const CLEAN_BASKET_REQUEST = 'CLEAN_BASKET_REQUEST';
export const CLEAN_BASKET_SUCCESS = 'CLEAN_BASKET_SUCCESS';
export const CLEAN_BASKET_FAILLURE = 'CLEAN_BASKET_FAILLURE';

export const UPDATED_PRODUCT_IN_BASKET_REQUEST = 'UPDATED_PRODUCT_IN_BASKET_REQUEST';
export const UPDATED_PRODUCT_IN_BASKET_SUCCESS = 'UPDATED_PRODUCT_IN_BASKET_SUCCESS';
export const UPDATED_PRODUCT_IN_BASKET_FAILURE = 'UPDATED_PRODUCT_IN_BASKET_FAILURE';
