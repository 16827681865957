import React, { useCallback, useState } from "react";
import { AppHeader, Button, Checkbox } from "../../components";
import { enumNavigationPath } from "../../enums";

import {PaymentProps} from './interfaces';

import styles from './styles.module.css';
import { enumToStringArray } from "../../functions";
import { MobilePaymentEnum, PaymentMethodeEnum } from "../../types";
import { useNavigation } from "../../hooks";

import CBimage from '../../assets/svgImages/carte.svg';
import ChequeImage from '../../assets/svgImages/cheque.svg';
import MobileImage from '../../assets/svgImages/mobile.svg';
import EspeceImage from '../../assets/svgImages/money.svg';

export const PaymentComponent:React.FC<PaymentProps> = ({
    onSelectedPayment,
    paymentTypeSave
})=> {

    const {navigateTo, navigate} = useNavigation();
    const [selectedPaymentMode, setSelectedPaymentMode] = useState<string | PaymentMethodeEnum>(paymentTypeSave?.type!);
    const [selectedmobilMoneyMode, setSelectedmobilMoneyMode] = useState<string | null>(null);

    const paymentTypeList = enumToStringArray(PaymentMethodeEnum);
    
    const mobilMoneyTypeList = enumToStringArray(MobilePaymentEnum);

    const handlePressButton = useCallback(()=> {
        if(paymentTypeSave){
           // navigateTo(enumNavigationPath.recap);
            navigateTo(enumNavigationPath.customerInformation);
        }
    }, [navigateTo, paymentTypeSave]);

    const renderPaymentImg = useCallback(()=> {
        switch (selectedPaymentMode) {
            case PaymentMethodeEnum.cb:
                return CBimage;
            case PaymentMethodeEnum.cheque:
                return ChequeImage;
            case PaymentMethodeEnum.esp:
                return EspeceImage;
            case PaymentMethodeEnum.mobilMoney:
                return MobileImage;
        }
    }, [selectedPaymentMode]);

    const handleSetPaymentMethode = useCallback((paymentMethode: PaymentMethodeEnum)=> {
        setSelectedPaymentMode(paymentMethode);
        if(paymentMethode !== PaymentMethodeEnum.mobilMoney){
            onSelectedPayment({
                type: paymentMethode
            })
        }
    }, [onSelectedPayment]);

    const renderPaymentSwitch = useCallback(()=> {
        return (
            <div className={styles.paymentSwitchContainer}>
                <div className={styles.paymentSwitchSubContainer}>
                    {
                        paymentTypeList.map((el, i)=> {
                            return (
                                <div 
                                    key={i}
                                    onClick={()=> handleSetPaymentMethode(el)}
                                    className={selectedPaymentMode === el ? 
                                    styles.paymentSwitchActiveButton : styles.paymentSwitchInActiveButton}
                                >
                                    <label>
                                        {el}
                                    </label>
                                </div>
                            )
                        })
                    }
                </div>
                <div className={styles.paymentSwitchChildContainer}>
                    <img 
                        alt={selectedPaymentMode}
                        src={renderPaymentImg()}
                    />
                </div>
            </div>
        )
    }, [
        handleSetPaymentMethode, 
        paymentTypeList, 
        renderPaymentImg, 
        selectedPaymentMode,
    ]);

    const renderMobilePayment = useCallback(()=> {
        if(selectedPaymentMode !== PaymentMethodeEnum.mobilMoney) return;
        
        return (
            <div className={styles.mobilPaymentContainer}>
                <h4 className={styles.mobilPaymentTxtTitle}>
                    Choisir votre opérateur mobile
                </h4>

                <div className={styles.mobilPaymentSubContainer}>
                    {
                        mobilMoneyTypeList.map((el, i)=> {
                            const isSelected = el === selectedmobilMoneyMode;
                            return (
                                <div className={styles.itemContainer}>
                                    <Checkbox 
                                    key={i}
                                    placeholder={el}
                                    isSelected={isSelected}
                                    onCheckboxPress={()=> {
                                        setSelectedmobilMoneyMode(el);
                                        onSelectedPayment({
                                            type: selectedPaymentMode,
                                            isMobilPayment: el
                                        });
                                    }}
                                />
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )
    }, [
        selectedPaymentMode, 
        mobilMoneyTypeList, 
        selectedmobilMoneyMode, 
        onSelectedPayment,
    ]);

    return (
        <div className={styles.container}>
            <AppHeader
                withBasket
                canGoback
                onBasketPress={()=> {
                navigateTo(enumNavigationPath.basket);
                }}
                onGoBack={()=> navigate(-1)}
            />
            <h3 className={styles.pageTitle}>
                Comment souhaitez-vous regler votre commande ?
            </h3>

            {renderPaymentSwitch()}

            <p className={styles.warningTxt}>
                *Il s’agit du mode de paiment que vous allez utiliser (aucun paiment ne se fait sur l’application)
            </p>

            {renderMobilePayment()}

            <Button 
                buttonName="Valider"
                onButtonPress={handlePressButton}
                containerStyle={{
                    marginLeft: 40,
                    marginRight: 40,
                    marginTop: 50
                }}
            />
        </div>
    )
}