import { 
    ADD_PRODUCT_IN_BASKET_FAILURE, 
    ADD_PRODUCT_IN_BASKET_REQUEST, 
    ADD_PRODUCT_IN_BASKET_SUCCESS,
    CLEAN_BASKET_FAILLURE,
    CLEAN_BASKET_REQUEST,
    CLEAN_BASKET_SUCCESS,
    DELETE_PRODUCT_IN_BASKET_FAILURE,
    DELETE_PRODUCT_IN_BASKET_REQUEST,
    DELETE_PRODUCT_IN_BASKET_SUCCESS,
    GET_BASKET_REQUEST,
    UPDATED_PRODUCT_IN_BASKET_FAILURE,
    UPDATED_PRODUCT_IN_BASKET_REQUEST,
    UPDATED_PRODUCT_IN_BASKET_SUCCESS,
} from "./actions";
import { 
    basketInitialState, 
    actionsBasket,
} from "./types";

const initialState:basketInitialState = {
    basket: [],
    addProductErrorState: false,
    deleteProductErrorState: false,
    onQuantityUpErrorState: false,
    onQuantityDownErrorState: false,
}

export default function BasketReducers(
    state:basketInitialState = initialState, 
    {type, payload}: actionsBasket
){
    switch(type) {
        case GET_BASKET_REQUEST:
            return {
                ...state,
                basket: state.basket,
            }
        case ADD_PRODUCT_IN_BASKET_REQUEST: 
            return {
                ...state,
            }
        case ADD_PRODUCT_IN_BASKET_SUCCESS:
            return {
                ...state,
                basket: payload,
            }
        case ADD_PRODUCT_IN_BASKET_FAILURE:
            return {
                ...state,
                addProductErrorState: payload
            }
        case UPDATED_PRODUCT_IN_BASKET_REQUEST: 
            return {
                ...state,
            }
        case UPDATED_PRODUCT_IN_BASKET_SUCCESS:
            return {
                ...state,
                basket: payload,
            }
        case UPDATED_PRODUCT_IN_BASKET_FAILURE:
            return {
                ...state,
            }
        case DELETE_PRODUCT_IN_BASKET_REQUEST: 
            return {
                ...state,
            }
        case DELETE_PRODUCT_IN_BASKET_SUCCESS: 
            return {
                ...state,
                basket: payload,
            }
        case DELETE_PRODUCT_IN_BASKET_FAILURE: 
            return {
                ...state,
                deleteProductErrorState: payload
            }
        case CLEAN_BASKET_REQUEST: 
            return {
                ...state,
                ...initialState,
            }
        default:
            return state
    }
}