import { 
    GET_ALL_PRODUCTS_FAILURE,
    GET_ALL_PRODUCTS_REQUEST,
    GET_ALL_PRODUCTS_SUCCESS,
    SELECTED_PRODUCT_FAILURE,
    SELECTED_PRODUCT_REQUEST,
    SELECTED_PRODUCT_SUCCESS,
} from "./actions";
import { 
    actionsProduct, 
    productInitialState 
} from "./types";

const initialState:productInitialState = {
    allProducts: [],
    selectedProduct: null,
    getAllProductErrorState: false,
}

export default function ProductReducers(
    state:productInitialState = initialState, 
    {type, payload}: actionsProduct
){
    switch(type) {
        case GET_ALL_PRODUCTS_REQUEST: 
            return {
                ...state,
            }
        case GET_ALL_PRODUCTS_SUCCESS:
            return {
                ...state,
                allProducts: payload,
            }
        case GET_ALL_PRODUCTS_FAILURE:
            return {
                ...state,
                getAllProductErrorState: payload
            }
        case SELECTED_PRODUCT_REQUEST: 
            return {
                ...state,
            }
        case SELECTED_PRODUCT_SUCCESS:
            return {
                ...state,
                selectedProduct: payload,
            }
        case SELECTED_PRODUCT_FAILURE:
            return {
                ...state,
            }
        default:
            return state
    }
}
