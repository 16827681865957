import { CollectionDbEnum, DinnerTableType } from '../types';
import {
    collection,
    db,
    doc,
    getDocs,
    query,
    updateDoc,
    where,
} from './config';

export const dinnerTablesModels = ()=> {

    const getDinnerTable = async (organisationID: string, tableNumber: number) => {
        try {
            let dinnerTables:DinnerTableType[] = [];
            const q = query(
                collection(db, CollectionDbEnum.DinnerTables),
                where("_organisationID", "==", organisationID),
                where("tableNumber", "==", tableNumber)
            );

            const querySnapshot = await getDocs(q);

            for (const doc of querySnapshot.docs) {
                const dinnerTable = doc.data() as DinnerTableType;
                dinnerTables.push(dinnerTable);
            }

            return {
                code: 200,
                data: dinnerTables[0]
            };
        } catch (error) {
            return {
                code: 500
            }
        }
    }

    const updatedDinnerTableData = async(newDinnerTable: DinnerTableType)=> {
        try {
            const docRef = doc(db, CollectionDbEnum.DinnerTables, newDinnerTable._id);
            await updateDoc(docRef, {
                ...newDinnerTable
            });

            return {
                code: 200
            }
        } catch (error) {
            return {
                code: 500
            }
        }
    }

    return {
        getDinnerTable,
        updatedDinnerTableData,
    }

}