import React, { useCallback, useEffect } from "react";
import {OnboardingComponent} from './Onboarding.component';
import { useDispatch, useSelector } from "react-redux";
import { useNavigation, useSessionData } from "../../hooks";
import { GET_DINNER_TABLE_REQUEST } from "../../modules";
import { enumNavigationPath } from "../../enums";
import { DinnerTableType } from "../../types";
  
export const Onboarding:React.FC = ({

})=> {

  const dispatch = useDispatch();
  const {sessionData} = useSessionData();
  const {navigateTo} = useNavigation();

  const dinnerTable: DinnerTableType | null = useSelector((state: any)=> state.DinnerTablesReducers.dinnerTable);
  const getDinnerTableFailure = useSelector((state: any)=> state.DinnerTablesReducers.getDinnerTableFailure);

  useEffect(()=> {
    if(dinnerTable){
      if(dinnerTable.isUsed === false) {
        navigateTo(enumNavigationPath.home);
      } else {
        alert("Impossible: Vérifier le numero de table saisi !");
      }
    }
  }, [dinnerTable, navigateTo]);

  const onGetDinnerTable = useCallback((tableNumber: number)=> {
    return dispatch({
      type: GET_DINNER_TABLE_REQUEST, 
      payload: {
        organisationID: sessionData?._id,
        tableNumber,
      }
    });
  }, [dispatch, sessionData]);

  return (
    <OnboardingComponent 
      onGetDinnerTable={onGetDinnerTable}
    />
  )
}
