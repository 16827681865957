import React, { useCallback, useState } from "react";

import { OnSiteOrToTakeAwayEnum, OnboardingProps } from "./interfaces";

import styles from "./styles.module.css";

import { enumNavigationPath } from "../../enums";
import { useNavigation } from "../../hooks";
import { useSessionData } from "../../hooks/useSessionData";
import { Button, Checkbox, Input } from "../../components";

export const OnboardingComponent: React.FC<OnboardingProps> = ({
  onGetDinnerTable
}) => {

  const {navigateTo} = useNavigation();
  const {sessionData} = useSessionData();

  const [tableNumber, setTableNumber] = useState<string>('');
  const [onSiteOrToTakeAway, setOnSiteOrToTakeAway] = useState<OnSiteOrToTakeAwayEnum>(OnSiteOrToTakeAwayEnum.onTheSite);

  const handlePressButton = useCallback(()=> {
    if(sessionData){
      if(onSiteOrToTakeAway === OnSiteOrToTakeAwayEnum.onTheTakeAway){
        navigateTo(enumNavigationPath.home);
      } else {
        if(tableNumber !== ''){
          onGetDinnerTable(Number(tableNumber));
        } else {
          alert('Saisir un numero de table !');
        }
      }
    }
  }, [
    sessionData, 
    onSiteOrToTakeAway, 
    navigateTo, 
    tableNumber, 
    onGetDinnerTable,
  ]);

  const renderCheckboxGroup = useCallback(()=> {
    return (
      <div className={styles.checkboxGroupContainer}>
        <Checkbox 
          placeholder="Sur place"
          isSelected={onSiteOrToTakeAway === OnSiteOrToTakeAwayEnum.onTheSite} 
          onCheckboxPress={()=> setOnSiteOrToTakeAway(OnSiteOrToTakeAwayEnum.onTheSite)}          
        />

        <Checkbox 
          placeholder="À emporter"
          isSelected={onSiteOrToTakeAway === OnSiteOrToTakeAwayEnum.onTheTakeAway} 
          onCheckboxPress={()=> setOnSiteOrToTakeAway(OnSiteOrToTakeAwayEnum.onTheTakeAway)}          
        />
      </div>
    )
  }, [onSiteOrToTakeAway]);

  const renderSelectTable = useCallback(()=> {
    if(onSiteOrToTakeAway === OnSiteOrToTakeAwayEnum.onTheTakeAway)
      return;
    return (
      <div className={styles.selectTableContainer}>
        <h2 className={styles.selectTableTitle}>
          Veuillez entrer votre numéro de table
        </h2>

        <Input 
          placeholder="ex: 2"
          value={tableNumber}
          onChangeText={e=> setTableNumber(e)}
          inputType='number'
        />
      </div>
    )
  }, [tableNumber, onSiteOrToTakeAway]);

  return (
    <div className={styles.container}>
      <h3 className={styles.restauName}>
        RESTAURANT
      </h3>
      <p className={styles.subTitle}>
        une app à votre image
      </p>

      <h2 className={styles.welcomeTxt}>
        Bienvenue sur notre interface de commande mobile
      </h2>

      {renderCheckboxGroup()}

      {renderSelectTable()}

      <Button 
        buttonName="Valider" 
        onButtonPress={handlePressButton}   
        containerStyle={{
          marginTop: 40
        }} 
      />
    </div>
  );
};
