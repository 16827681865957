import styles from "./styles.module.css";
import ChampionCup from "../../assets/svgIcons/champion-cup.svg";
import Emoji from "../../assets/svgIcons/emoji-laugh.svg";
import { AppHeader, Input, } from "../../components";
import { enumNavigationPath } from "../../enums";
import { useNavigation } from "../../hooks";
import { CustomerInformationProps } from "./interfaces";
import { useCallback, useState } from "react";
import { regexTelephone } from "../../utils";

export const CustomerInformationPage: React.FC<CustomerInformationProps> = ({
  onSaveInformations
}) => {
  const { navigateTo, navigate } = useNavigation();
  const [name, setName] = useState<string>("")
  const [phone, setPhone] = useState<string>("")
  // const [adress, setAdress] = useState<string>("")
  // const [message, setMessage] = useState<string>("")

  const handlePressButton = useCallback(() => {
    onSaveInformations({ name, phone })
    navigateTo(enumNavigationPath.recap);
  }, [onSaveInformations, name, phone, navigateTo]);

  return (
    <div className={styles.container}>
      <AppHeader
        withBasket
        canGoback
        onBasketPress={() => {
          navigateTo(enumNavigationPath.basket);
        }}
        onGoBack={() => navigate(-1)}
      />
      <div className={styles.titleRow}>
        <img src={ChampionCup} alt="Champion cup" />

        <label className={styles.titleText}>Saisie informations</label>
        <img src={Emoji} alt="Emoji laugh" />
      </div>
      <div className={styles.inputStyle}>
        <Input
          width={250}
          placeholder="Prénom et nom"
          value={name}
          onChangeText={setName}
        />
      </div>
      <div className={styles.inputStyle}>
        <Input
          width={250}
          placeholder="Téléphone ex:+33..."
          value={phone}
          onChangeText={setPhone}
        />
      </div>
      {!regexTelephone.test(phone) && phone !== '' && (
        <div className={styles.errorMessage}>Veuillez saisir un numéro valide</div>
      )}
      {/* <div className={styles.inputStyle}>
        <Input
          width={250}
          placeholder="Adresse"
          value={adress}
          onChangeText={setAdress}
        />
      </div>
      <div className={styles.inputStyle}>
        <textarea
          autoFocus
          className={styles.textArea}
          value={message}
          placeholder="Message"
          inputMode="text"
          onChange={(e: any) => setMessage(e.target.value)}
        />
      </div> */}
      <div className={styles.buttonContainer}>
        <div
          onClick={handlePressButton}
          className={styles.validationButon}>
          <label>Valider</label>
        </div>
      </div>
    </div>
  );
};


