import React from "react";
import {
  DinnerTableType,
  RestaurantOrderStatusEnum,
  Sale,
} from "../../types";

export interface SuccessProps {
  sale: Sale | null;
  logo: string;
  navigateAndReset: () => void;
  onDeleteSaleAction: (saleID: string) => void;
  dinnerTable: DinnerTableType | null;
}

export const steps = [
  {
    order: 0,
    state: RestaurantOrderStatusEnum.new,
    title: "Votre commande a bien été prise en compte",
    subTitle: "Nous lancerons la préparation dans quelques minutes",
  },
  {
    order: 1,
    state: RestaurantOrderStatusEnum.inProgress,
    title: "Votre commande est en cours de préparation",
    subTitle: "Merci de patienter , vous serez bientot servi",
  },
  {
    order: 2,
    state: RestaurantOrderStatusEnum.ready,
    title: "Votre commande est prete !",
    subTitle: "Un serveur(se) arrive avec votre commande",
  },
  {
    order: 3,
    state: RestaurantOrderStatusEnum.inTable,
    title: "Vous etes servi !",
    subTitle: "Merci d’avoir patienté, Bonne appeti !",
  },
];
