import { useDispatch, useSelector } from "react-redux";
import { Sale, basketType, LOCAL_STORAGE, DinnerTableType, CustomerInfoType } from "../../types";
import { RecapComponentPage } from "./Recap.component";
import {
  RESET_SALE_STATE,
  SET_NEW_TRANSACTION_REQUEST,
  paymetTypeState,
} from "../../modules";
import { useNavigation, useSessionData } from "../../hooks";
import { useCallback, useEffect, useState } from "react";
import { enumNavigationPath } from "../../enums";

export const Recap: React.FC = () => {
  const { sessionData } = useSessionData();
  const dispatch = useDispatch();
  const { navigateTo } = useNavigation();

  const basket: basketType[] = useSelector(
    (state: any) => state.BasketReducers.basket
  );
  const paymentTypeSave: paymetTypeState = useSelector(
    (state: any) => state.PaymentTypeReducers.payment
  );
  const addNewTransactionSuccess = useSelector(
    (state: any) => state.TransactionReducers.addNewTransactionSuccess
  );
  const addNewTransactionErrorState = useSelector(
    (state: any) => state.TransactionReducers.addNewTransactionErrorState
  );
  const [localNewSale, setLocalNewSale] = useState<Sale>();

  const onResetSaleState = useCallback(() => {
    return dispatch({ type: RESET_SALE_STATE });
  }, [dispatch]);

  const dinnerTable: DinnerTableType | null = useSelector(
    (state: any) => state.DinnerTablesReducers.dinnerTable
  );

  const customerInfos: CustomerInfoType = useSelector(
    (state: any) => state.CustomerInfoReducers.customerData
  );

  useEffect(() => {
    if (addNewTransactionSuccess) {
      onResetSaleState();
      window.localStorage.setItem(
        LOCAL_STORAGE.SALE_KEY,
        JSON.stringify(localNewSale)
      );
      navigateTo(enumNavigationPath.success);
    }
    if (addNewTransactionErrorState) {
      alert(
        "Impossible de passer votre commande, veillez reessayé!"
      );
      window.localStorage.removeItem(LOCAL_STORAGE.SALE_KEY);
      onResetSaleState();
    }
  }, [
    addNewTransactionSuccess,
    addNewTransactionErrorState,
    navigateTo,
    onResetSaleState,
    localNewSale,
  ]);

  const onAddNewSale = useCallback(
    (newSale: Sale) => {
      setLocalNewSale(newSale);
      return dispatch({
        type: SET_NEW_TRANSACTION_REQUEST,
        payload: newSale,
      });
    },
    [dispatch]
  );

  return (
    <RecapComponentPage
      customerInformations={customerInfos}
      dinnerTable={dinnerTable}
      basket={basket}
      sessionData={sessionData}
      paymentTypeSave={paymentTypeSave}
      onAddNewSale={onAddNewSale}
    />
  );
};
