import React, { useCallback } from "react";

import { ButtonProps } from "./interfaces";

import styles from "./styles.module.css";

export const Button: React.FC<ButtonProps> = ({
  buttonName,
  onButtonPress,
  containerStyle,
  disabled = false,
}) => {
  const handleButtonPress = useCallback(() => {
    if (!disabled) {
      onButtonPress();
    }
  }, [onButtonPress, disabled]);

  return (
    <div
      onClick={handleButtonPress}
      className={disabled ? styles.containerDisabled : styles.container}
      style={containerStyle}
    >
      {buttonName}
    </div>
  );
};
