import {
    put,
    call,
    delay,
    takeEvery
} from 'redux-saga/effects';

import { 
    ADD_PRODUCT_IN_BASKET_FAILURE, 
    ADD_PRODUCT_IN_BASKET_REQUEST, 
    ADD_PRODUCT_IN_BASKET_SUCCESS,
    CLEAN_BASKET_FAILLURE,
    CLEAN_BASKET_REQUEST,
    CLEAN_BASKET_SUCCESS,
    DELETE_PRODUCT_IN_BASKET_FAILURE,
    DELETE_PRODUCT_IN_BASKET_REQUEST,
    DELETE_PRODUCT_IN_BASKET_SUCCESS,
    UPDATED_PRODUCT_IN_BASKET_REQUEST,
    UPDATED_PRODUCT_IN_BASKET_SUCCESS,
} from "./actions";
import { basketType } from '../../types';
import { arraysAreEqual } from '../../functions';

function* addProductInBasketRequest({ payload }: any): any {
    const basket: basketType[] = payload.basket.slice();
    let newItem: basketType = payload.newItem;

    const existingItemIndex = basket.findIndex((item: basketType) =>
        item._id === newItem._id &&
        item.sideDishSelected === newItem.sideDishSelected &&
        arraysAreEqual(item.extraSideDish!, newItem.extraSideDish!) &&
        arraysAreEqual(item.saucesSelected!, newItem.saucesSelected!)
    );
    
    if (existingItemIndex !== -1) {
        basket[existingItemIndex].quantitySelected += 1;
    } else {
        const newItemWithQuantity = { ...newItem, quantitySelected: 1 };
        basket.push(newItemWithQuantity);
    }
    yield put({type: ADD_PRODUCT_IN_BASKET_SUCCESS, payload: basket});
}

function* deleteProductInBasketRequest({ payload }: any): any {
    const basket: basketType[] = payload.basket.slice();
    let itemData: basketType = payload.itemData;

    const itemIndex = basket.findIndex(item => item._id === itemData._id);

    if(itemData.quantitySelected === 1){
        basket.splice(itemIndex, 1);
    } else {
        const findItem = basket.find(item=> {
            if(
                item._id === itemData._id &&
                item.sideDishSelected === itemData.sideDishSelected
            ){
                if(
                    itemData.extraSideDish?.length! > 0 && 
                    arraysAreEqual(item.extraSideDish!, itemData.extraSideDish!)
                ){
                    return item;
                }
                return item;
            }
        });
        if(findItem){
            findItem.quantitySelected -= 1;
        }
    }

    yield put({type: DELETE_PRODUCT_IN_BASKET_SUCCESS, payload: basket});
}

function* updatedBasketRequest({ payload }: any): any {
    let basketCopy = [...payload.basket];
    if(Number(payload.quantity) === 0) {
        basketCopy.splice(payload.index, 1);
    } else {
        basketCopy.map((el, i)=> {
            if(i === payload.index) {
                el.quantitySelected = Number(payload.quantity)
            }
        })
    }
    yield put({type: UPDATED_PRODUCT_IN_BASKET_SUCCESS, payload: basketCopy});
}

export default function* basketSaga() {
    yield takeEvery(ADD_PRODUCT_IN_BASKET_REQUEST, addProductInBasketRequest);
    yield takeEvery(DELETE_PRODUCT_IN_BASKET_REQUEST, deleteProductInBasketRequest);
    yield takeEvery(UPDATED_PRODUCT_IN_BASKET_REQUEST, updatedBasketRequest);
}
