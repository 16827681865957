import { basketType } from "./basket.types";
import { DinnerTableType } from "./dinnerTable.type";
import { OrganisationTypeEnum } from "./organisation.types";

export interface Sale {
  _organisationID: string;
  _organisationType: OrganisationTypeEnum | string;
  _sellerID?: string;
  _id: string;
  basket: basketType[];
  totalToPay: number;
  totalPay: number;
  reduction: number;
  paymentMethode: paymentMethode;
  client?: string | clientDataType;
  restaurantOrderStatus?: RestaurantOrderStatusEnum | string | null;
  onlineOrderStatus?: OnlineOrderStatusEnum | string | null;
  restauLocalOrderData?: restauLocalOrderDataType | null;
  createAt: string;
}

export interface restauLocalOrderDataType {
  table?: DinnerTableType | null
  clientInfos?: clientInfosRestauType | null
}

export interface clientInfosRestauType {
  clientName: string
  clientPhone: string
}

export interface clientDataType {
  firstname: string;
  lastname: string;
  phone: string;
  country?: string;
  city?: string;
  street?: string;
  adress: string;
}

export interface paymentMethode {
  methode: PaymentMethodeEnum | string;
  mobilePayment?: MobilePaymentEnum | string | null;
  credit?: creditState | null;
}

export interface creditState {
  _saleID: string;
  _clientID: string;
  ammont: number;
  isClose: boolean;
}

export interface saleInitialState {
  allSales: Sale[];
  getAllSaleErrorState: boolean;
  addNewSaleSuccess: boolean;
  addNewSaleFailure: boolean;
  updatedCreditSuccess: boolean;
  updatedCreditFailure: boolean;
  updatedOnlinePaymentSuccess: boolean;
  updatedOnlinePaymentFailure: boolean;
  updatedSaleOrderStateSuccess: boolean;
  updatedSaleOrderStateFailure: boolean;
  deleteSaleSuccess: boolean;
  deleteSaleFailure: boolean;
}

export interface saleActionType {
  type: string;
  payload: any;
}

export enum PaymentMethodeEnum {
  esp = "Espèces",
  mobilMoney = "Paiement Mobile",
  cb = "Carte Bancaire",
  cheque = "Chèque",
  // credit = 'Crédit',
}

export enum MobilePaymentEnum {
  orangeMoney = "Orange money",
  mtnMomo = "MTN momo",
  moovMoney = "Moov money",
  wave = "Wave",
}

export enum RestaurantOrderStatusEnum {
  new = "new",
  inProgress = "in progress",
  ready = "ready",
  inTable = "in table",
}

export enum OnlineOrderStatusEnum {
  new = "new",
  beingProcessed = "being processed",
  courseDelivery = "In the course of delivery",
  livery = "livery",
}
