import React, {
    useCallback,
    useEffect,
    useRef,
    useState,
} from "react";

import { BasketProps } from './interfaces';

import styles from './styles.module.css';

import { enumNavigationPath } from "../../enums/enumNavigationPath";
import { AppHeader, Button, ProductCard } from "../../components";
import { useNavigation } from "../../hooks";
import { ProductBasketCard } from "./components";
import { formatNumberToPrice } from "../../functions";

export const BasketComponent: React.FC<BasketProps> = ({
    basket,
    products,
    onAddProductInBasket,
    onDeleteProductInBasket
}) => {

    const { navigateTo, navigate } = useNavigation();
    const [totalBasketValue, setTotalBasketValue] = useState<number>(0);

    const fixedBottomDivHeight = useRef<any>(null);

    useEffect(() => {
        let tempTotal = totalBasket();
        setTotalBasketValue(tempTotal);
    }, [basket]);

    const handleButtonPress = useCallback(() => {
        if (basket.length > 0) {
            navigateTo(enumNavigationPath.payment);
        }
    }, [basket, navigateTo]);

    const totalBasket = useCallback(() => {
        let total = 0;

        basket.map(el => {
            const price = el.productSellingPrice[el.productSellingPrice.length - 1].price;
            total += price * el.quantitySelected;
            el.extraSideDish?.map(supp => {
                total += supp.price;
            })
        })

        return total;
    }, [basket]);

    const getDivHeight = useCallback(() => {
        if (fixedBottomDivHeight.current) {
            const height = fixedBottomDivHeight.current.clientHeight;
            return height;
        }
        return 0;
    }, []);

    const renderBottomContainer = useCallback(() => {
        return (
            <div className={styles.bottomContainerWrapper}>
                <div className={styles.bottomContainer}>
                    <div className={styles.bottomSubContainer}>
                        <label>
                            Total de la commande
                        </label>
                        <label>
                            {formatNumberToPrice(totalBasketValue)} fcfa
                        </label>
                    </div>

                    <Button
                        buttonName="Commander"
                        onButtonPress={handleButtonPress}
                        containerStyle={{
                            margin: 20
                        }}
                    />
                </div>

            </div>
        )
    }, [
        totalBasketValue,
        handleButtonPress
    ]);

    const renderBasketChoices = useCallback(() => {
        return (
            <React.Fragment>
                <div className={styles.BasketheadContainer}>
                    <h3 className={styles.BasketTitle}>
                        Vos choix
                    </h3>

                    <div className={styles.buttonContainer}>
                        <Button
                            buttonName="+ Ajouter un produit"
                            onButtonPress={() => navigateTo(enumNavigationPath.home)}
                        />
                    </div>
                </div>
                <div className={styles.productBasketContainer}>
                    {
                        basket.map((el, i) => {
                            return (
                                <div
                                    key={i}
                                    className={styles.productItemContainer}
                                >
                                    <ProductBasketCard
                                        productInBasket={el}
                                        onDeleteProductInBasket={onDeleteProductInBasket}
                                        onAddProductInBasket={onAddProductInBasket}
                                    />
                                </div>
                            )
                        })
                    }
                </div>
            </React.Fragment>
        )
    }, [
        navigateTo,
        basket,
        onDeleteProductInBasket,
        onAddProductInBasket
    ]);

    const renderSuggestions = useCallback(() => {
        return (
            <div
                className={styles.suggestionWrapper}
            >
                <h4 className={styles.suggestionproductTxt}>
                    D'autre utilisateurs ont aussi ajouté
                </h4>

                <div className={styles.suggestionproductSubContainer}>
                    {
                        products.map((el, i) => {
                            const price = formatNumberToPrice(el.productSellingPrice[el.productSellingPrice.length - 1].price);
                            return (
                                <div
                                    key={i}
                                    className={styles.cardItemContainer}
                                >
                                    <ProductCard
                                        productImgUri={el.productImage}
                                        productName={el.productName}
                                        price={price}
                                        onPress={() => onAddProductInBasket({
                                            ...el,
                                            quantitySelected: 0
                                        })}
                                    />
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )
    }, [
        products,
        formatNumberToPrice,
        onAddProductInBasket,
    ]);

    return (
        <div
            className={styles.container}
        >
            <div
                className={styles.subContainer}
                style={{
                    height: window.innerHeight - getDivHeight()
                }}
            >
                <AppHeader
                    canGoback
                    onGoBack={() => navigate(-1)}
                />
                {renderBasketChoices()}

                {renderSuggestions()}
                <div
                    className={styles.suggestionproductContainer}
                    ref={fixedBottomDivHeight}
                >
                    {renderBottomContainer()}
                </div>
            </div>


        </div>
    )
}