import {
    put,
    takeEvery
} from 'redux-saga/effects';

import * as actions from "./actions";

function* setSavePaymentType({ payload }: any): any {
    yield put({type: actions.SET_PAYMENT_TYPE_SUCCESS, payload: payload});
}

export default function* paymentSaga() {
    yield takeEvery(actions.SET_PAYMENT_TYPE_REQUEST, setSavePaymentType);
}
