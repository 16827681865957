export enum enumNavigationPath {
  onboarding = "/:id",
  home = "/acceuil",
  carte = "/carte",
  product = "/produit",
  basket = "/panier",
  payment = "/paiement",
  recap = "/recap",
  success = "/succes",
  bill = "/bill",
  customerInformation = "/customerInformation"
}
