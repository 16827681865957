import React, { useCallback, useMemo } from "react";
import {
  ProductCard,
  Button,
  ItemSeparatorWrapper,
  AppHeader,
} from "@components";
import { enumNavigationPath } from "../../enums/enumNavigationPath";

import { HomeProps } from "./interfaces";

import styles from "./styles.module.css";
import { useNavigation } from "../../hooks";
import { formatNumberToPrice } from "../../functions";
import PlaceholderImage from "../../assets/svgImages/placeholder.svg";
export const HomeComponent: React.FC<HomeProps> = ({
  onSelectedCategory,
  onSelectedProduct,
  productsByCategories,
  basket,
  sessionData,
  banners,
}) => {
  const { navigateTo } = useNavigation();

  const images = useMemo(
    () => [PlaceholderImage, PlaceholderImage, PlaceholderImage],
    []
  );

  const renderCarrouselPlacHolder = useCallback(() => {
    if (banners?.listBanners && banners?.listBanners?.length !== 0) return null;

    return (
      <div className={styles.carrouselContainer}>
        {images.map((el, i) => {
          return (
            <div
              key={i}
              style={{
                backgroundImage: `url(${el})`,
              }}
              className={styles.carrouselItem}
            />
          );
        })}
      </div>
    );
  }, [images, banners]);

  const renderCarrousel = useCallback(() => {
    if (banners?.listBanners && banners?.listBanners?.length === 0) return null;

    return (
      <div className={styles.carrouselContainer}>
        {banners?.listBanners.map((el, i) => {

          return (
            <div
              key={i}
              style={{
                backgroundImage: `url(${el.img})`,
              }}
              className={styles.carrouselItem}
            />
          );
        })}
      </div>
    );
  }, [banners]);

  return (
    <div className={styles.container}>
      <AppHeader
        withBasket
        onBasketPress={() => {
          navigateTo(enumNavigationPath.basket);
        }}
      />
      <h2 className={styles.homeTitleTxt}>Accueil</h2>
      {renderCarrousel()}
      {renderCarrouselPlacHolder()}
      <div className={styles.wrapperContainer}>
        {productsByCategories.map((element, index) => {
          return (
            <ItemSeparatorWrapper key={index} label={element.categoryName}>
              <div className={styles.separatorWrapper}>
                <div className={styles.productContainerRow}>
                  {element.produits.slice(-3).map((produit, j) => {
                    const price =
                      produit.productSellingPrice[
                        produit.productSellingPrice.length - 1
                      ].price;

                    return (
                      <div key={j} className={styles.productCardWrapper}>
                        <ProductCard
                          productName={produit.productName}
                          productImgUri={produit.productImage}
                          price={formatNumberToPrice(price)}
                          onPress={() => {
                            onSelectedProduct(produit);
                            navigateTo(enumNavigationPath.product);
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
                <div className={styles.buttonWrapper}>
                  <Button
                    buttonName="Voir plus"
                    onButtonPress={() => {
                      onSelectedCategory(element);
                      navigateTo(enumNavigationPath.carte);
                    }}
                  />
                </div>
              </div>
            </ItemSeparatorWrapper>
          );
        })}
      </div>
    </div>
  );
};
