import React, { useCallback, useEffect, useState } from "react";
import { HomeComponent } from "./Home.component";

import {
  GET_ALL_CATEGORIES_REQUEST,
  GET_ALL_PRODUCTS_REQUEST,
  GET_BANNERS_REQUEST,
  SELECTED_CATEGORY_REQUEST,
  SELECTED_PRODUCT_REQUEST,
} from "../../modules";
import { useDispatch, useSelector } from "react-redux";
import { Category, Product, productsByCategories } from "../../types";
import { useSessionData } from "../../hooks/useSessionData";
import { groupProductsByCategories } from "../../functions";

export const Home: React.FC = () => {
  const dispatch = useDispatch();
  const { sessionData } = useSessionData();

  const [productsByCategories, setProductsByCategories] = useState<
    productsByCategories[]
  >([]);

  const categories = useSelector(
    (state: any) => state.CategoriesReducers.allCategories
  );
  const products:Product[] = useSelector(
    (state: any) => state.ProductReducers.allProducts
  );
  const basket = useSelector((state: any) => state.BasketReducers.basket);
  const banners = useSelector((state: any) => state.BannersReducers.banners);

  useEffect(() => {
    if (sessionData) {
      onGetCategories(sessionData._id);
      onGetProduct(sessionData._id);
      onGetBanners(sessionData._id);
    }
  }, [sessionData]);

  useEffect(() => {
    if (categories.length > 0 && products.length > 0) {
      const filteredByAcitveProducts = products.filter(el=> el.isActive === true);
      const productsByCategoriesTmp = groupProductsByCategories(
        categories,
        filteredByAcitveProducts
      );
      setProductsByCategories(productsByCategoriesTmp);
    }
  }, [products, categories]);

  const onGetCategories = useCallback(
    (organisationID: string) => {
      return dispatch({
        type: GET_ALL_CATEGORIES_REQUEST,
        payload: organisationID,
      });
    },
    [dispatch]
  );

  const onSelectedCategory = useCallback(
    (category: Category) => {
      return dispatch({ type: SELECTED_CATEGORY_REQUEST, payload: category });
    },
    [dispatch]
  );

  const onGetProduct = useCallback(
    (organisationID: string) => {
      return dispatch({
        type: GET_ALL_PRODUCTS_REQUEST,
        payload: organisationID,
      });
    },
    [dispatch]
  );

  const onSelectedProduct = useCallback(
    (product: Product) => {
      return dispatch({ type: SELECTED_PRODUCT_REQUEST, payload: product });
    },
    [dispatch]
  );

  const onGetBanners = useCallback(
    (organisationID: string) => {
      return dispatch({ type: GET_BANNERS_REQUEST, payload: organisationID });
    },
    [dispatch]
  );
  return (
    <HomeComponent
      banners={banners}
      sessionData={sessionData}
      productsByCategories={productsByCategories}
      basket={basket}
      onSelectedCategory={onSelectedCategory}
      onSelectedProduct={onSelectedProduct}
    />
  );
};
