import React from 'react';
import './App.css';

import {Provider} from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import {
  Navigations
} from './navigations'; 
import {
  store,
  persistor
} from './modules';

function App() {
  // creer le hook et l'appeler ici dans un useEffect,
  // verifier si l'objet 'sale' => verifier params => 'in table' clear localstorage, 
  // redirect auto vers la home
  return (
    <Provider store={store}>
      <PersistGate 
        loading={null} 
        persistor={persistor}
      >
        <Navigations />
      </PersistGate>
    </Provider>
  );
}

export default App;
