import React from "react";
import { RecapComponentProps } from "./interface";
import styles from "./styles.module.css";
import { RecapCommandOnly } from "../RecapCommandOnly";

export const RecapComponent: React.FC<RecapComponentProps> = ({
  sale,
  status,
  tableNumber,
  commandType,
  commandNumber,
  totalToPay,
  customerInfos
}) => {
  return (
    <div className={styles.recapContainer}>
      <div className={styles.row}>
        <label className={styles.labelsText}>Status: {status}</label>
        <label className={styles.labelsText}>N° de table: {tableNumber}</label>
      </div>
      <div className={styles.row}>
        <label className={styles.labelsText}>{commandType}</label>
        <label className={styles.labelsText}>
          N° de commande: {commandNumber}
        </label>
      </div>
      <RecapCommandOnly 
        paymentMethod={sale.paymentMethode} 
        basket={sale.basket}
        totalToPay={totalToPay}
        customerInfos={customerInfos}
      />
    </div>
  );
};
