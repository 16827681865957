import React from "react";
import { ProductCardProps } from "./interface";
import styles from "./styles.module.css";
import { limitText_10, limitText_15 } from "../../../functions";
export const ProductCard: React.FC<ProductCardProps> = ({
  productImgUri,
  productName,
  price,
  onPress,
}) => {
  return (
    <div className={styles.productCardWrappeer}>
      <p className={styles.productName}>
        {limitText_10(productName)}
      </p>
      <div className={styles.productCard}>
        <img
          className={styles.imageContainer}
          src={productImgUri}
          alt={productName}
        />
        <p className={styles.choiceText}> {price} fcfa</p>
      </div>
      <div onClick={onPress} className={styles.chooseButton}>
        Ajouter +
      </div>
    </div>
  );
};
