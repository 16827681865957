import { initializeApp } from "firebase/app";
import {
  getFirestore,
  doc, setDoc, getDoc, query, where,
  updateDoc, collection, getDocs,
  onSnapshot, deleteDoc
} from "firebase/firestore";
import {
  getStorage,
  ref, uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import { getAnalytics } from 'firebase/analytics'
import { EnvironmentTypeEnum } from "../enums";


const IS_PRODUCTION = process.env.REACT_APP_APP_ENV === EnvironmentTypeEnum.PRODUCTION;

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: IS_PRODUCTION ? process.env.REACT_APP_MEASUREMENT_ID : '',
};
const app = initializeApp(firebaseConfig);
if (IS_PRODUCTION) {
  const analytics = getAnalytics(app);
}
const db = getFirestore(app);

export {
  db, doc, setDoc, getDoc, updateDoc, collection, getDocs, query, where,
  getStorage, ref, uploadBytes, getDownloadURL,
  onSnapshot, deleteDoc
};
